.Main {
	position: relative;
	display: grid;
	gap: 0.625rem;
	padding: 1.25rem var(--padding);
	background-color: white;
	z-index: 20;
}

.Card {
}

.Item {
	composes: ScaleFadeIn from './Animations.module.css';
	--delay: 0;
	animation-delay: var(--delay);
	border: solid 1px currentColor;
	box-sizing: border-box;
	border-radius: var(--radius);
	padding: 1.875rem;
	opacity: 0;
}

.Card.Visible .Item :global {
	animation-name: ScaleFadeIn;
}

.Bar {
	display: flex;
	justify-content: space-between;
	padding: 0 0 1.875rem 0;
}

.Title {
	position: relative;
	font-size: clamp(6.25rem, 5.1267rem + 4.6083vi, 12.5rem);
	letter-spacing: -0.05em;
	font-variation-settings:
		'wght' 400,
		'wdth' 125;
}

.Bg {
	position: relative;
	opacity: 0;
}

.Fg {
	position: absolute;
	top: 0;
}

.Number {
}

.Text {
	max-width: 30ch;
}

@media (min-width: 1024px) {
	.Main {
		grid-auto-flow: column;
		gap: 1.25rem;
	}

	.Title {
		font-size: clamp(6.25rem, 2.0833rem + 6.5104vw, 12.5rem);
	}

	.Item {
		padding: 2.5rem;
	}
}
