.Main {
	position: relative;
	padding: 20vmin var(--padding);
	background-color: white;
	z-index: 20;
}

.Title {
	text-align: center;
	padding: 0 0 0.7em 0;
	text-wrap: balance;
}

.Buttons {
	display: flex;
	justify-content: center;
	gap: 1.25rem;
}

.Button {
	font-size: clamp(1rem, 0.8725rem + 0.5229vw, 1.5rem);
	padding: 0.8em 1.4em;
	font-variation-settings: 'wght' 700;
	border-radius: 2em;
	text-decoration: none;
	box-sizing: border-box;
	transition:
		opacity 250ms linear,
		transform 250ms var(--ease-in-out-circ);
}

.Button:hover {
	opacity: 0.8;
}

.Button:active {
	transform: scale(0.95);
}

.Primary {
	color: white;
	background-color: var(--blue);
	border: solid 2px var(--blue);
}

.Secondary {
	border: solid 2px currentColor;
}

@media (min-width: 768px) {
	.Main {
		padding: 7.5vw var(--padding);
	}
}
