import { PageDocumentDataBodyBenefitsSlice } from './@types/types.generated'
import styles from './Benefits.module.css'
import typoStyles from './Typography.module.css'
import clsx from 'clsx'
import { asHTML, asText, isFilled } from '@prismicio/client'
import hljs from 'highlight.js/lib/core'
import json from 'highlight.js/lib/languages/json'
import 'highlight.js/styles/vs2015.css'
import './highlight.css'

hljs.registerLanguage('json', json)

export function Benefits(props: PageDocumentDataBodyBenefitsSlice) {
	const {
		items,
		primary: { code }
	} = props
	return /* HTML */ `<div class="${styles.Main}">
		<div class="${styles.Top}">
			<div class="${clsx(typoStyles.Label, styles.Label)}">ServiceNow</div>
			<h3 class="${typoStyles.Heading3}">arc46 squid</h3>
		</div>
		${isFilled.richText(code)
			? `<div class="${styles.Code}"><pre>${hljs.highlight(asText(code), { language: 'json' }).value}</pre></div>`
			: ''}
		<div class="${styles.Items}">
			${items
				.map((item, index) => {
					return /* HTML */ `<div class="${styles.Item}" style="--delay:${250 + index * 150}ms">
						<div class="${clsx(typoStyles.Label, styles.Label)}">Benefit</div>
						${isFilled.keyText(item.title)
							? `<div class="${clsx(typoStyles.Heading3, styles.Title)}">${item.title}</div>`
							: ''}${isFilled.richText(item.text)
							? `<div class="${typoStyles.Copy}">${asHTML(item.text)}</div>`
							: ''}
					</div>`
				})
				.join('')}
		</div>
	</div>`
}
