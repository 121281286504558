import styles from './Cookie.module.css'
import cookie from 'js-cookie'

export class CookieController {
	constructor() {
		const node = document.querySelector(`.${styles.Main}`) as HTMLElement
		const [decline, accept] = Array.from(node.querySelectorAll(`.${styles.Button}`) as NodeListOf<HTMLButtonElement>)
		const consent = cookie.get('cookie-consent')
		if (consent === 'accepted') {
			// @ts-ignore
			window.consentGranted()
		} else if (consent === 'declined') {
			//noop
		} else {
			node.classList.add(styles.Visible)

			accept.addEventListener('click', () => {
				// @ts-ignore
				window.consentGranted()
				cookie.set('cookie-consent', 'accepted', { expires: 365 })
				node.classList.remove(styles.Visible)
			})

			decline.addEventListener('click', () => {
				cookie.set('cookie-consent', 'declined', { expires: 365 })
				node.classList.remove(styles.Visible)
			})
		}
	}
}
