.Main {
	position: sticky;
	top: 0;
	box-sizing: border-box;
	padding-inline: calc(var(--padding) * 2);
	padding-block-start: 2.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 10;
}

.Fixed {
	position: fixed;
	width: 100vw;
}

.Inverted {
	color: white;
}

.Visible {
}

.Logo {
	composes: SlideFadeInDown from './Animations.module.css';
	opacity: 0;
}

.Visible .Logo :global {
	animation-name: SlideFadeInDown;
}

.Nav {
	font-family: 'Archivo Italic', sans-serif;
	display: flex;
	font-size: clamp(0.75rem, 0.6863rem + 0.2614vw, 1rem);
	gap: 0.5em;
	font-weight: 700;
	align-items: center;
}

.Item {
	composes: SlideFadeInDown from './Animations.module.css';
	--delay: 0;
	opacity: 0;
	animation-delay: var(--delay);
}

.Visible .Item :global {
	animation-name: SlideFadeInDown;
}

.Link {
	display: block;
	text-decoration: none;
	transition:
		opacity 250ms linear,
		transform 250ms var(--ease-in-out-circ);
}

.Link:hover {
	opacity: 0.8;
}

.Link:active {
	transform: scale(0.95);
}

.Button {
	padding: 0.8em 1.2em;
	border-radius: 2em;
	color: white;
	background-color: var(--blue);
}

.Inverted .Button {
	color: black;
	background-color: white;
}

@media (min-width: 768px) {
	.Main {
		padding-inline: var(--padding);
		padding-block-start: 10vmin;
	}

	.Nav {
		gap: 1.5em;
	}
}
