import squidStyles from './squid/Squid.module.css'
import headerStyles from './Header.module.css'
import benefitsStyles from './Benefits.module.css'
import cardsStyles from './Cards.module.css'
import ctaStyles from './CallToAction.module.css'
import { SquidController } from './squid/SquidController'
import styles from './HTML.module.css'
import { update } from '@tweenjs/tween.js'
import { HeaderController } from './HeaderController'
import { BenefitsController } from './BenefitsController'
import { CardsController } from './CardsController'
import { CookieController } from './CookieController'
import { CallToActionController } from './CallToActionController'

export interface Controller {
	show(): void

	load?(): Promise<void>

	resize?(): void

	scroll?(): void

	update?(time: number): void
}

export class App {
	private readonly controllers: Controller[]
	public squidController?: SquidController
	public hasSquid = false

	constructor() {
		this.update = this.update.bind(this)
		history.scrollRestoration = 'manual'

		document.body.classList.remove(styles.Fixed)
		window.scrollTo(0, 0)
		document.body.classList.add(styles.Fixed)

		const squidNode = document.body?.querySelector(`.${squidStyles.Main}`) as HTMLElement
		if (squidNode) {
			this.squidController = new SquidController(squidNode)
			this.hasSquid = true
		}

		this.controllers = [
			...this.getNodes(headerStyles.Main).map((node) => new HeaderController(node, this)),
			...this.getNodes(benefitsStyles.Main).map((node) => new BenefitsController(node)),
			...this.getNodes(cardsStyles.Main).map((node) => new CardsController(node)),
			...this.getNodes(ctaStyles.Main).map((node) => new CallToActionController(node))
		]
		new CookieController()
		window.requestAnimationFrame(this.update)

		this.resize()
		this.scroll()
		this.init()
	}

	async init() {
		await this.squidController?.load()
		document.body.classList.remove(styles.Fixed)
		this.resize()
		this.scroll()
		window.addEventListener('resize', this.resize.bind(this))
		window.addEventListener('scroll', this.scroll.bind(this))
		this.squidController?.show()
		this.controllers.forEach((controller) => controller.show?.())
	}

	getNodes(className: string): HTMLElement[] {
		return Array.from(document.body?.querySelectorAll(`.${className}`) as NodeListOf<HTMLElement>)
	}

	resize() {
		this.squidController?.resize()
		this.controllers.forEach((controller) => controller.resize?.())
	}

	scroll() {
		this.squidController?.scroll()
		this.controllers.forEach((controller) => controller.scroll?.())
	}

	update(time: number) {
		window.requestAnimationFrame(this.update)
		update(time)
		this.squidController?.update(time)
		this.controllers.forEach((controller) => controller.update?.(time))
	}
}
