.Main {
	position: relative;
	padding: 45vmin var(--padding) 2.5rem var(--padding);
	display: grid;
	align-items: start;
	gap: 2.5rem;
	background-color: white;
	z-index: 20;
}

.Top {
	composes: SlideFadeInDown from './Animations.module.css';
	animation-delay: 150ms;
	padding: 0 var(--padding);
}

.Main.Visible .Top :global {
	animation-name: SlideFadeInDown;
}

.Label {
	padding: 0 0 1.5em 0;
}

.Code {
	composes: ScaleFadeIn from './Animations.module.css';
	opacity: 0;
	animation-delay: 500ms;
	font-size: 14px;
	line-height: 1.3;
	border-radius: var(--radius);
	padding: 1.25rem;
	background-color: black;
	color: white;
	overflow: hidden;
	box-sizing: border-box;
	overflow-x: auto;
}

.Main.Visible .Code :global {
	animation-name: ScaleFadeIn;
}

.Items {
	display: grid;
	padding: var(--padding);
	gap: 2.5rem;
}

.Item {
	composes: SlideFadeInDown from './Animations.module.css';
	--delay: 0;
	animation-delay: var(--delay);
}

.Main.Visible .Item :global {
	animation-name: SlideFadeInDown;
}

.Title {
	max-width: 75%;
	padding: 0 0 0.425em 0;
}

@media (min-width: 768px) {
	.Main {
		padding: 7.5vw var(--padding);
	}
}

@media (min-width: 1280px) {
	.Main {
		grid-template-columns: max-content minmax(0, 75ch) minmax(max-content, 1fr);
	}

	.Top {
		padding: 0 5vw 0 0;
	}

	.Items {
		padding: 0 2.5vw 0 0;
		grid-row: 1;
		grid-column: 2;
	}

	.Code {
		grid-row: 1;
		grid-column: 3;
	}
}
