import styles from './Squid.module.css'
import { SquidController } from './SquidController'

export class Nav {
	private readonly buttons: HTMLElement[]
	private readonly indicator: HTMLElement

	constructor(
		private readonly node: HTMLElement,
		private readonly controller: SquidController
	) {
		this.indicator = node.querySelector(`.${styles.Indicator}`) as HTMLElement
		this.buttons = Array.from(node.querySelectorAll(`.${styles.Button}`) as NodeListOf<HTMLElement>)
		this.buttons.forEach((button, index) =>
			button.addEventListener('click', () => this.controller.scrollTo(index + 1))
		)
	}

	update() {
		const { elapsedIndex, elapsedFraction, containerHeight, windowHeight, scrollY } = this.controller

		const black = containerHeight - windowHeight * 0.5 - scrollY < 0
		const elapsed = elapsedIndex + elapsedFraction
		const index = Math.max(Math.round(elapsed) - 2, 0)

		this.indicator.style.setProperty('--index', `${index}`)
		elapsed >= 2 ? this.node.classList.add(styles.Visible) : this.node.classList.remove(styles.Visible)
		black ? this.node.classList.add(styles.Black) : this.node.classList.remove(styles.Black)

		this.buttons.forEach((dot, i) =>
			i === index ? dot.classList.add(styles.Active) : dot.classList.remove(styles.Active)
		)
	}
}
