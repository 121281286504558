import styles from './Squid.module.css'
import { StageManager } from './StageManager'

export class Panel {
	public top = 0
	public height = 0
	private visible = false
	private fading = false
	private timer = 0

	constructor(
		private readonly node: HTMLElement,
		private readonly stage: StageManager,
		private readonly index: number,
		private readonly last = false
	) {
		this.fadeEnd = this.fadeEnd.bind(this)
	}

	fadeEnd() {
		this.visible = false
		this.fading = false
		this.node.classList.remove(styles.Visible)
		this.node.classList.remove(styles.Hidden)
	}

	resize() {
		const { top, height } = this.node.getBoundingClientRect()
		this.top = top
		this.height = height
	}

	update() {
		const { elapsedIndex, elapsedFraction } = this.stage

		if (!this.visible && elapsedIndex === this.index && (elapsedFraction <= 0.25 || this.last)) {
			window.clearTimeout(this.timer)
			this.visible = true
			this.fading = false
			this.node.classList.remove(styles.Fade)
			this.node.classList.add(styles.Visible)
		}

		if (this.visible && !this.fading && ((elapsedFraction > 0.25 && !this.last) || elapsedIndex !== this.index)) {
			window.clearTimeout(this.timer)
			this.fading = true
			this.node.classList.add(styles.Fade)
			this.timer = window.setTimeout(this.fadeEnd, 500)
		}
	}
}
