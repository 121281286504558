.Body {
	--blue: #5956ff;
	--grey: #d1d1d1;

	--padding: clamp(0.625rem, -1.5092rem + 8.7558vw, 12.5rem);
	--radius: 0.375rem;

	font-family: 'Archivo Variable', sans-serif;
	width: 100vw;
	margin: 0;
	color: black;
	background-color: white;
	overflow-x: hidden;
	font-size: 0.875rem;
	font-variation-settings:
		'wght' 400,
		'wdth' 100;
}

.Body.Fixed {
	height: 100lvh;
	overflow: hidden;
}

.Main {
	position: relative;
}
