import { App, Controller } from './App'
import styles from './Header.module.css'
import { delay } from './utils/delay'

export class HeaderController implements Controller {
	constructor(
		private readonly node: HTMLElement,
		private readonly app: App
	) {}

	async show() {
		if (this.app.hasSquid) {
			await delay(1500)
		}
		this.node.classList.add(styles.Visible)
	}
}
