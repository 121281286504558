import styles from './Squid.module.css'
import { Panel } from './Panel'
import { Easing, Tween } from '@tweenjs/tween.js'
import { delay } from '../utils/delay'
import { Nav } from './Nav'
import { Controller } from '../App'
import Tracking from './Tracking'
import { StageManager } from './StageManager'

export class SquidController implements Controller {
	private readonly stageManager: StageManager
	private readonly panels: Panel[]
	private readonly sections: HTMLElement[]
	private readonly numSections: number
	private readonly nav: Nav
	private readonly tracking: Tracking

	private sectionPositions: number[] = []
	private needsScrollUpdate = false
	private introElapsed = 0
	private timer = 0
	private elapsed = 0

	public inView = false
	public elapsedIndex = 0
	public elapsedFraction = 0
	public containerHeight = 0
	public windowHeight = 0
	public scrollY = 0

	constructor(private readonly node: HTMLElement) {
		this.scrollEnd = this.scrollEnd.bind(this)

		this.sections = Array.from(node.querySelectorAll(`.${styles.Section}`) as NodeListOf<HTMLElement>)
		this.numSections = this.sections.length

		this.tracking = new Tracking([...this.sections.map((_, index) => `stage-${index}`)], this)
		this.nav = new Nav(node.querySelector(`.${styles.Nav}`) as HTMLElement, this)
		this.stageManager = new StageManager(node.querySelector(`.${styles.Stage}`) as HTMLElement, this)

		this.panels = Array.from(node.querySelectorAll(`.${styles.Panel}`) as NodeListOf<HTMLElement>).map(
			(panel, index) => new Panel(panel, this.stageManager, index + 1, index + 1 === this.numSections)
		)
	}

	async load() {
		await this.stageManager.load()
	}

	async show() {
		await delay(250)
		await this.animateIntro()
		this.introElapsed = 1
	}

	async animateIntro() {
		// this.elapsed = 1
		return new Promise((resolve) => {
			new Tween({ elapsed: 0 })
				.easing(Easing.Exponential.InOut)
				.to({ elapsed: 1 }, 1500)
				.onUpdate(({ elapsed }) => {
					this.needsScrollUpdate = true
					this.elapsed = elapsed
				})
				.onComplete(resolve)
				.start()
		})
	}

	scrollTo(index: number) {
		window.scrollTo({ top: this.sectionPositions[index] + 5, behavior: 'smooth' })
	}

	scrollEnd() {
		if (this.introElapsed < 1 || this.elapsed >= 4) {
			return
		}
		const fraction = this.elapsed % 1
		const target = fraction > 0.25 ? Math.ceil(this.elapsed) - 1 : Math.floor(this.elapsed) - 1
		this.scrollTo(target)
	}

	scroll() {
		window.clearTimeout(this.timer)

		this.scrollY = window.scrollY
		let index = 0

		for (let i = 0; i < this.sectionPositions.length; i++) {
			const top = this.sectionPositions[i] - this.scrollY
			if (top >= 0) {
				break
			}
			index = i
		}

		const clampedIndex = Math.min(index, this.numSections - 1)
		const diff = this.sectionPositions[clampedIndex + 1] - this.sectionPositions[clampedIndex]
		const elapsedIndex = clampedIndex + this.introElapsed
		const elapsedFraction = (this.scrollY - this.sectionPositions[clampedIndex]) / diff
		this.elapsed = elapsedIndex + elapsedFraction

		this.inView = this.scrollY <= this.containerHeight

		if (this.inView) {
			this.timer = window.setTimeout(this.scrollEnd, 1000)
		}

		this.needsScrollUpdate = true
	}

	resize() {
		this.scrollY = window.scrollY
		const { height } = this.node.getBoundingClientRect()
		this.windowHeight = window.innerHeight
		this.containerHeight = height
		this.stageManager.resize()
		this.panels.forEach((panel) => panel.resize())
		this.sectionPositions = this.sections.map(
			(section) => this.scrollY + (section.getBoundingClientRect().top || 0)
		)
		this.sectionPositions.push(this.containerHeight)
	}

	update(time: number) {
		if (!this.inView) return

		this.stageManager.update()

		this.elapsedIndex = Math.floor(this.elapsed)
		this.elapsedFraction = this.elapsed - this.elapsedIndex

		if (this.needsScrollUpdate) {
			this.needsScrollUpdate = false
			this.tracking.update()
			this.panels.forEach((panel) => panel.update())
			this.nav.update()
		}
	}
}
